@font-face {
  font-family: 'Inter UI';
  font-style: normal;
  font-weight: 400;
  src: url('./font/Inter-UI-Regular.woff2') format('woff2'),
  url('./font/Inter-UI-Regular.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Inter UI';
  font-style: normal;
  font-weight: 500;
  src: url('./font/Inter-UI-Medium.woff2') format('woff2'),
  url('./font/Inter-UI-Medium.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Inter UI';
  font-style: normal;
  font-weight: 700;
  src: url('./font/Inter-UI-Bold.woff2') format('woff2'),
  url('./font/Inter-UI-Bold.woff') format('woff');
  font-display: swap;
}

@tailwind base;

@tailwind components;

@tailwind utilities;

.hero-image {
  background-size: 120% 65%;
}

@screen md {
  .hero-image {
    background-size: 130% 70%;
    background-position-x: 30%;
    background-position-y: bottom;
  }
}

@screen lg {
  .hero-image {
    background-size: 130% 95%;
    background-position-x: 30%;
    background-position-y: 60px;
  }
}

.spacing-tight {
  letter-spacing: -2px;
}

.swiper-wrapper {
  transform: translate3d(0px, 0px, 0px);
  backface-visibility: hidden;
}

.laptop-screen {
  width: 59%;
  position: absolute;
  top: 25px;
  left: 50%;
  transform: translateX(-50%);
}

.active.active-bg-green-500 {
  @apply bg-green-500;
}

.active.active-bg-lightblue-500 {
  @apply bg-lightblue-500;
}

.active.active-bg-blue-500 {
  @apply bg-blue-500;
}

.image-wrapper > * {
  @apply absolute left-0 top-0 w-full h-full object-cover;
  font-family: "object-fit: cover;";
}

.open-menu-wrapper {
  height: 195px;
}

.toggle-menu {
  .hamburger {
    @apply block;
  }

  .close {
    @apply hidden
    }
}

.open-toggle-menu {
  .hamburger {
    @apply hidden;
  }

  .close {
    @apply block;
  }
}
